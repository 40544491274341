import React, { Component } from "react";
// import Img from "gatsby-image";
import Slider from "react-slick";
import BackgroundImage from "gatsby-background-image";

class Carousel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _className: "loading",
      isTouch: false
    };
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this._onKeyboard);
  }
  componentDidMount() {
    document.addEventListener("keyup", this._onKeyboard);

    const isTouch = 'ontouchstart' in window ? true : false;
    this.setState({
      isTouch: isTouch
    })
  }

  _onKeyboard(e) {
    const key = e.key || e.keyCode;
    // console.log(key)
    switch (key) {
      case "ArrowRight":
        document.querySelector("button.slick-next").click();
        break;

      case "ArrowLeft":
        document.querySelector("button.slick-prev").click();
        break;

      default:
        break;
    }
  }

  _onInit(){
    this.setState({
      _className: ""
    })
  }

  render() {
    const { _className, isTouch } = this.state
    // const { idx } = this.state;
    const { images } = this.props;
    // console.log(images)
    const settings = {
      speed: 600,
      dots: false,

      infinite: true,
      variableWidth: isTouch ? false: true,      
      slidesToShow: 1,
      slidesToScroll: 1,
      //lazyLoad: "progressive",
      onInit: this._onInit.bind(this)
    };

    const _images = images.filter(( {image} ) => {
      // console.log(image.localFile)
      return image.localFile !== null;
    });
// console.log(images)
// console.log(_images)
    return (
      <div className={"carousel "+_className}>
        <Slider ref={slider => (this.slider = slider)} {...settings}>
          {_images.map( ({ image }, i) => {
            // const {height,aspectRatio} = image.localFile.childImageSharp.resolutions
            // const width = height * aspectRatio
            // console.log(image)
            const slideClass = 
            image.localFile.childImageSharp.fluid.aspectRatio < 1 
            ? "is-portrait"
            : "is-landscape"
            return(
              <div key={i} className="slide">
                {/* <Img
                  fluid={image.localFile.childImageSharp.fluid}
                  // sizes={{...image.localFile.childImageSharp.fluid.sizes, aspectRatio: image.localFile.childImageSharp.fluid.aspectRatio}}
                  imgStyle={{ 
                    width: width+"px",
                    height: height+"px"
                  }}
                /> */}
                {/* <img 
                  src={image.localFile.childImageSharp.sizes.src} 
                  srcSet={image.localFile.childImageSharp.sizes.srcSet} 
                  sizes={image.localFile.childImageSharp.sizes.sizes} 
                  alt=""
                  /> */}
                  <BackgroundImage
                    className={slideClass}
                    fluid={image.localFile.childImageSharp.fluid}
                    fadeIn={false}
                    style={{
                      backgroundSize: "cover"
                    }}
                  />
              </div>
            )
          })}
        
        </Slider>
      </div>
    );
  }
}

export default Carousel;
