import React, { Component } from 'react';
import { ContextHoc } from '../../context/ContextHoc'

class TexteSplit extends Component {

    componentDidMount(){
        //window.addEventListener('load', this._handleLoaded);
    
        if(window.Splitting){
            this._split()
        }else{
            setTimeout(() => {
                this._split()
            }, 500)
        }
    }

    _split(){
        // console.log(this)
        const colors = this.props.context.options.data.colors.map(el => el.color)
        // console.log(colors)
        const target = this.refs.texte
        const split = window.Splitting({ target: target, by: 'chars' });
  
        const len = colors.length
        let index = 0
        split.forEach( s => {
//   console.log(s)
            s.chars.forEach( (char, i) => {
                // console.log(index, len, colors[index])
                index = index < len - 1 
                ? index+1
                : 0

                char.style.setProperty('--color', colors[index]);
            });
            
          });
    }

    render() {
        const {texte} = this.props
        return (
            <div
                ref="texte"
                className="texte texte-split"
                dangerouslySetInnerHTML={{
                    __html: texte
                }}
            ></div>
        )
    }
}

export default ContextHoc(TexteSplit);