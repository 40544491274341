import React from "react"
import { graphql } from "gatsby"
//import PubSub from 'pubsub-js';
//import cookie from 'react-cookies'

import SEO from "../components/SEO"
import Carousel from "../components/ui/Carousel"
import TexteSplit from "../components/ui/TexteSplit"
// import TitreSplit from "../components/ui/TitreSplit"
// const locales = require('../../config/i18n')

class PageComingSoon extends React.Component {
  constructor(props) {
      super(props)
      this.state = {}
  }

  render() {
    // console.log(this.props)
    // const {i18n} = this.props.pageContext
    // const locale = i18n.locale
    //console.log(locales)

    const { options, about: {
        data: {
            carousel,
            texte,
            links,
            titre_texte,
            contact
        }
    } } = this.props.data
// console.log(carousel)
    return (
      <>
        <div className="coming-soon">
            <SEO
                pageTitle={options.data.title.text}
                pageDescription={options.data.description.text}
                template="template-coming-soon"
                pageBanner={options.data.poster.url}
                // pathname={location.pathname}
            />

            <Carousel images={carousel} />
            <div className="content">
                <section className="about">
                    <div className="row">
                        <div className="col-md-3 hidden-xs">
                            <aside className="fS">
                                <ul className="links">
                                {links.map(({label,link},i) => (
                                    <li key={i}>
                                        <a
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            href={link.url} >
                                            {label.text}
                                        </a>
                                    </li>
                                ))}
                                </ul>
                            </aside>
                        </div>
                        <div className="col-md-9 col-xs-12">
                            <div className="about-content">
                                <ul className="links xs-only">
                                {links.map(({label,link},i) => (
                                    <li key={i}>
                                        <a
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            href={link.url} >
                                            {label.text}
                                        </a>
                                    </li>
                                ))}
                                </ul>

                                <TexteSplit texte={texte.html} />

                            </div>
                        </div>
                    </div>
                </section>
                <section className="team">
                    <div className="row">
                        <div className="col-md-3 hidden-xs"></div>
                        <div className="col-md-9 col-xs-12">
                            
                            {titre_texte.map((el,i) => (
                                <div className="titre_texte" key={i}>
                                    <h2 className="fS">{el.titre.text}</h2>
                                    {/* <div
                                        className="texte"
                                        dangerouslySetInnerHTML={{
                                            __html: el.texte1.html
                                        }}
                                    ></div> */}
                                    <TexteSplit texte={el.texte1.html} />
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
                <section className="footer contact">
                    <div className="row">
                        <div className="col-md-3 hidden-xs"></div>
                        <div className="col-md-9 col-xs-12">
                            <h2 className="fS">Contact</h2>
                            {/* <div
                                className="texte"
                                dangerouslySetInnerHTML={{
                                    __html: contact.html
                                }}
                            ></div> */}
                            <TexteSplit texte={contact.html} />
                            <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/EspaceBertrandGrimont/">Facebook</a><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/espacebertrandgrimont/">Instagram</a>
                        </div>
                    </div>
                </section>
            </div>
        </div>
      </>
    )
  }
}

export default PageComingSoon

export const pageQuery = graphql`
    query PageComingSoon($locale: String!) {
        options: prismicOptions {
            ..._prismicOptions
        }
        about: prismicAbout(lang: { eq: $locale }) {
            ..._prismicAbout
        }
    }
`
